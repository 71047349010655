export const priceCardsInfo = [
  {
    id: 0,
    name: 'Test',
    price: '99zł',
    activationFee: '0zł',
    numberCampaigns: '1',
    budget: '100-500zł',
    duration: '1 tydzień',
    value: 'Test',
    provision: '0%',
  },
  {
    id: 1,
    name: 'Start',
    price: '299zł',
    activationFee: '299zł',
    numberCampaigns: 'Do 2',
    budget: '500-1000zł',
    duration: 'Dowolny',
    value: 'Start',
    provision: '5%',
    elementUnderPrice: '+ prowizja',
  },
  {
    id: 2,
    name: 'Plus',
    price: '599zł',
    activationFee: '599zł',
    numberCampaigns: 'Do 4',
    budget: '1000-2000zł',
    duration: 'Dowolny',
    value: 'Plus',
    provision: '7%',
    elementUnderPrice: '+ prowizja',
  },
];

export const priceIndividual = {
  id: 3,
  name: 'Indywidualny',
  activationFee: '-',
  numberCampaigns: '-',
  budget: '-',
  duration: '-',
  value: 'Indywidualny',
  provision: '10%',
  elementUnderPrice: '+ prowizja',
};
